<template>
  <div>
    <p>
      For all your express shipments from 1 to 25.000 kg, from and to the countries with Welpa Trans offices:
      <router-link :to="getRoute('contact')">contact us</router-link>!
    </p>

    <strong>{{translate('BE')}}</strong>
    <dl v-for="destination in destinations" :key="destination">
      <dt>{{ translate(destination) }}</dt>
      <dd>
        <div class="row" v-for="item in employeesFromDestination(destination)" :key="item.name">
          <div class="col-md-3">
            <router-link
              :to="getRoute(getEmployeeRouteName(item), { name: item.slugName })"
            >{{ item.name }}</router-link>
          </div>
          <div class="col-md-3">
            <a :href="'tel:' + item.phone">{{ formatPhone(item.phone) }}</a>
          </div>
          <div class="col-md-3">
            <a :href="'mailto:'+item.email">{{ formatEmail(item.email) }}</a>
          </div>
        </div>
      </dd>
    </dl>
  </div>
</template>

<script>
import employeeMixins from "@/mixins/EmployeeMixins";

export default {
  mixins: [employeeMixins],
  props: ["destinations"]
};
</script>
