<template>
  <div class="list-group">
    <router-link
      v-for="category in subCategories"
      :key="category.name"
      :to="getRoute(category.name)"
      class="list-group-item list-group-item-action"
      :class="{ 'active': isActiveRoute(category.name) }"
    >{{ navTitle(category) }}</router-link>
  </div>
</template>

<script>
import { categoryUtility } from "@/regira_modules/vue/categories";

export default {
  props: ["parentName", "excludeParent"],
  computed: {
    subCategories() {
      const items = categoryUtility.getChildCategories(
        this.parentName,
        this.categories
      );
      if (!this.excludeParent) {
        const parentCategory = categoryUtility.findCategory(
          this.parentName,
          this.categories
        );
        items.unshift(parentCategory);
      }
      return items;
    }
  }
};
</script>
