import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes';
import appConfig from '@/app-config';

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: appConfig.baseUrl,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});
