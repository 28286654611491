<template>
  <div>
    <p>
      <strong>Quality, customer service, professionalism and transparency, ...</strong>
      these are just a few words of utmost importance for our company. They represent a daily target for all our colleagues.
      <br>
      <br>Therefore, our ISO certification stands for our continuous striving towards optimizing our service package.
    </p>
    <p align="center">
      <br>
      <br>
      <a
        :href="getDocumentUrl(certificateCode)"
        @click.prevent="openDocument(certificateCode)"
      >Download certificate</a>
    </p>
  </div>
</template>

<script>
import { mapDocuments } from "@/regira_modules/vue/documents";

export default {
  mixins: [mapDocuments()],
  data() {
    return {
      certificateCode: "IsoCertificate"
    };
  }
};
</script>
