<template>
  <div class="who-is-who">
    <BackgroundImage :src="pageImage" />
    <PageWrapper>
      <h1>{{ pageTitle }}</h1>
      <div class="row">
        <div class="col-lg-8">
          <h2>{{ translate('BE') }}</h2>
          <div class="row">
            <div class="col-xl-6" v-for="item in employees" :key="item.email">
              <Employee :item="item" />
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <SubMenu :parentName="'about'" />
        </div>
      </div>
    </PageWrapper>
  </div>
</template>

<script>
import employeeMixins from "@/mixins/EmployeeMixins";
import Employee from "@/employees/ListItem";
import SubMenu from "@/components/SubMenu";
import { createPageMixins } from "@/regira_modules/vue/categories";

export default {
  mixins: [createPageMixins(), employeeMixins],
  components: { SubMenu, Employee },
  data() {
    return {
      ignoreView: true
    };
  }
};
</script>