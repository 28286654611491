<template>
  <div>
    <p>
      <em v-for="title in employeeTitles(item)" :key="title">
        {{ title }}
        <br>
      </em>
    </p>
    <dl class="row">
      <dt class="col-4">{{ translate('phone') }}</dt>
      <dd class="col-8">
        <a :href="'tel:'+ item.phone">{{ formatPhone(item.phone) }}</a>
      </dd>
      <dt class="col-4">{{ translate('fax') }}</dt>
      <dd class="col-8">
        <a :href="'tel:'+ item.fax">{{ formatPhone(item.fax) }}</a>
      </dd>
      <dt class="col-4">{{ translate('mobile') }}</dt>
      <dd class="col-8">
        <a :href="'tel:'+ item.mobile">{{ formatPhone(item.mobile) }}</a>
      </dd>
      <dt class="col-4">Email</dt>
      <dd class="col-8 text-nowrap">
        <a :href="'mailto:' + item.email">{{ formatEmail(item.email) }}</a>
      </dd>
    </dl>
  </div>
</template>

<script>
import employeeMixins from "@/mixins/EmployeeMixins";

export default {
  mixins: [employeeMixins],
  props: ["item"]
};
</script>
