<template>
  <div>
    <BackgroundImage :src="pageImage" />
    <PageWrapper>
      <h1>{{ pageTitle }}</h1>
      <Sitemap :nodes="sitemapNodes" />
    </PageWrapper>
  </div>
</template>

<script>
import Sitemap from "@/components/Sitemap";
import ioc from "@/infrastructure/ioc";
import {
  createPageMixins,
  categoryUtility
} from "@/regira_modules/vue/categories";

export default {
  mixins: [createPageMixins()],
  components: { Sitemap },
  data() {
    return {
      sitemapNodes: []
    };
  },
  mounted() {
    this.sitemapNodes = categoryUtility.getSitemapNodes(
      this.$store.state.categories.items
    );
  }
};
</script>
