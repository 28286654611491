<template>
  <div class="destinations">
    <PageWrapper>
      <h1>{{ pageTitle }}</h1>
      <component :is="pageView" v-if="pageView" />
      <Map />
    </PageWrapper>
  </div>
</template>

<script>
import Map from "@/gis/Map";
import { createPageMixins } from "@/regira_modules/vue/categories";

export default {
  mixins: [createPageMixins()],
  components: { Map }
};
</script>
