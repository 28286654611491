<template>
  <div>
    <h3 class="text-upper">
      {{ age }} years of Welpa Trans! And we have moved to Wommelgem...
    </h3>
    <p>
      April 2, 1984! {{ age }} years ago Welpa Trans was established! Reason
      enough to not let this pass by unnoticed and to dwell on it for a moment.
      Set up as a subsidiary of Welpatrans BV Rotterdam,...
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      age: new Date().getYear() + 1900 - 1984,
    };
  },
};
</script>
