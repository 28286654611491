<template>
  <header>
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-light">
        <router-link :to="getRoute('home')" class="navbar-brand" :title="translate('home')">
          <img src="@/assets/images/welpatrans-logo.png" :alt="translate('logo')" />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          @click="isNavbarCollapsed = !isNavbarCollapsed"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarMenu" :class="{ show: !isNavbarCollapsed }">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item" v-for="category in headerCategories" :key="category.name">
              <router-link :to="getRoute(category.name)" class="nav-link">{{ navTitle(category) }}</router-link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import { autoCloseNavbarMenu } from "@/assets/js/navbar-helper";
import { getHeaderCategories } from "@/regira_modules/vue/categories/category-utility";

let navigationChangedHandled = false;

export default {
  data() {
    return {
      isNavbarCollapsed: true
    };
  },
  computed: {
    headerCategories() {
      return getHeaderCategories(this.categories);
    }
  },
  created() {
    if (!navigationChangedHandled) {
      this.$router.afterEach((to, from) => {
        this.isNavbarCollapsed = true;
      });
      navigationChangedHandled = true;
    }
  },
  mounted() {
    autoCloseNavbarMenu(() => {
      this.isNavbarCollapsed = true;
    });
  }
};
</script>

<style scoped>
.navbar-brand img {
  height: 3rem;
}
.nav-item {
  padding: 0.5rem;
}
</style>
