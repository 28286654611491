<template>
  <div id="app">
    <Layout/>
  </div>
</template>

<script>
import Layout from "@/views/Layout";

export default {
  components: {
    Layout
  }
};
</script>

