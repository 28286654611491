<template>
  <div>
    <BackgroundImage :src="pageImage" />
    <PageWrapper>
      <h1>{{ pageTitle }}</h1>
      <div class="row">
        <div class="col-lg-8">
          <p>
            <strong>{{ translate('documents') }}</strong>
          </p>
          <ul>
            <li v-for="file in documents" :key="file.code">
              <a
                :href="getDocumentUrl(file)"
                @click.prevent="openDocument(file)"
              >{{ getDocumentTitle(file) }}</a>
            </li>
          </ul>
        </div>
        <div class="col-lg-4">
          <SubMenu :parentName="'about'" />
        </div>
      </div>
    </PageWrapper>
  </div>
</template>

<script>
import SubMenu from "@/components/SubMenu";
import { createPageMixins } from "@/regira_modules/vue/categories";
import { mapDocuments } from "@/regira_modules/vue/documents";

export default {
  mixins: [createPageMixins(), mapDocuments()],
  components: { SubMenu }
};
</script>