<template>
  <div id="page" :class="{ 'fixed-header': fixedHeader }">
    <Languages v-if="$lang.languages.length > 1"/>
    <Header/>
    <main class="container">
      <router-view/>
    </main>
    <Footer/>
  </div>
</template>

<script>
import Languages from "@/components/Languages";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  components: {
    Languages,
    Header,
    Footer
  },
  data() {
    return {
      scrollPosition: 0
    };
  },
  computed: {
    fixedHeader() {
      return this.scrollPosition > 20;
    }
  },
  //watch: {},
  mounted() {
    window.onscroll = () => {
      this.scrollPosition = window.scrollY;
    };
  }
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
  position: relative;
  font-family: arial, helvetica, sans-serif;
}
h1 {
  font-size: 1.25rem;
  color: #0372ca;
}
h2 {
  font-size: 1.1rem;
}
.box {
  background-color: #fff;
  border-radius: 0.25rem;
  border: solid 1px #eee;
  box-shadow: 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.3);
  padding: 0.75rem;
  font-size: 0.75rem;
  margin-bottom: 1.5rem;
}
.box h3 {
  margin-bottom: 0.5rem;
}

#page {
  position: relative;
}
main {
  min-height: calc(100vh - 9.5rem - 1.75rem);
}
.page-view {
  margin: -2.5rem 0.75rem 0;
}
.page-view .box {
  min-height: 20rem;
}
header {
  background-color: #eff0f2;
  margin-bottom: 0.5rem;
}
footer {
  background-color: #eff0f2;
  padding: 0.5rem 0;
}
.pre {
  white-space: pre;
}
a {
  cursor: pointer;
}

.fixed-header header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
.fixed-header main {
  margin-top: 5rem;
}


@media (max-width: 720px) {
  .page-view {
    margin: 0;
    margin-top: 1.5rem;
  }
}
</style>
