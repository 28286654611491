<template>
  <ul>
    <li v-for="node in nodes" :key="node.value.name">
      <router-link :to="getRoute(node.value.name)" class="nav-link">{{ categoryTitle(node.value) }}</router-link>
      <Sitemap v-if="node.children.length" :nodes="node.children"/>
    </li>
  </ul>
</template>

<script>
import Sitemap from "@/components/Sitemap";

export default {
  name: "Sitemap",
  components: { Sitemap },
  props: ["nodes"]
};
</script>
