<template>
  <div class="home">
    <BackgroundImage :src="pageImage" />
    <div class="page-view">
      <h1 class="sr-only">{{ pageTitle }}</h1>
      <component :is="pageView" v-if="pageView" />
    </div>
  </div>
</template>

<script>
import { createPageMixins } from "@/regira_modules/vue/categories";

export default {
  mixins: [createPageMixins()]
};
</script>
