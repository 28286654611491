<template>
  <div>
    <h3 class="text-upper">
      {{ age }} jaar Welpa Trans! En we zijn verhuisd naar Wommelgem...
    </h3>
    <p>
      Op 2 april is het {{ age }} jaren geleden dat Welpa Trans is
      gesticht in 1984!
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      age: new Date().getYear() + 1900 - 1984,
    };
  },
};
</script>

