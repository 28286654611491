const autoCloseNavbarMenu = (callback) => {
    // close navbarMenu when routed
    window.onload = function () {
        const navbarAnchors = document.querySelectorAll('header .nav-item a, header .navbar-brand');
        const navbarCollapsedMenu = document.getElementById('navbarMenu');
        navbarAnchors.forEach(a => {
            a.onclick = function () {
                navbarCollapsedMenu.classList.remove('show');
                if (typeof (callback) === "function") {
                    callback();
                }
            }
        });
    };
};

export { autoCloseNavbarMenu };