<template>
  <div>
    <!-- <div class="background-image" :style="{ pageImage: 'url(' + src + ')' }"></div> -->
    <img :src="src">
  </div>
</template>

<script>
export default {
  props: ["src"]
};
</script>

<style scoped>
.background-image {
  background-size: cover;
  background-position: top left;
  background-repeat: no-repeat;
  height: 438px;
  margin: 0 auto;
}
img {
  width: 100%;
}
</style>