import appConfig from '@/app-config';
import JsonService from '@/regira_modules/entities/json-service';
import EntityService from '@/regira_modules/firebase/entity-service';
import CategoryManager from './category-manager';
import EntityManager from '@/regira_modules/entities/entity-manager';
import EmployeeManager from '@/infrastructure/employee-manager';

const api = appConfig.entityApi;
const version = appConfig.version;
const Service = api == '/data' ? JsonService : EntityService;

// var jsonService = new JsonService('/data', 'documents');
// var fbService = new Service(api, 'documents');
// jsonService.list()
//     .then(async (items) => {
//         items.forEach(async x => await fbService.save(x));
//     });

export const categoryManager = new CategoryManager(new Service(api, "categories", version));
export const translationManager = new EntityManager(new Service(api, "translations", version));
export const sitesettingsManager = new EntityManager(new Service(api, "company", version));
export const documentManager = new EntityManager(new Service(api, "downloads", version));
export const urlmappingManager = new EntityManager(new JsonService(`${appConfig.baseUrl}/data`, 'urlmappings', version));
export const employeeManager = new EmployeeManager(new Service(api, 'employees', version));
export const destinationManager = new EntityManager(new JsonService(`${appConfig.baseUrl}/data`, 'destinations', version))

export default {
    categoryManager,
    translationManager,
    sitesettingsManager,
    documentManager,
    employeeManager,
    destinationManager,
    urlmappingManager
};
