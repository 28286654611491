import { EventHandler } from '@/regira_modules/events';

//console.debug("EventHandler", { EventHandler });

class MapManager {
    constructor() {
        this.defaultStyle = new ol.style.Style({
            fill: new ol.style.Fill({
                color: 'rgba(255, 255, 255, 0.6)'
            }),
            stroke: new ol.style.Stroke({
                color: '#319FD3',
                width: 1
            }),
            text: new ol.style.Text({
                font: '12px Calibri,sans-serif'
            })
        });
        this.activeStyle = new ol.style.Style({
            stroke: new ol.style.Stroke({
                color: '#00f',
                width: 2
            }),
            fill: new ol.style.Fill({
                color: 'rgba(0,0,255,0.1)'
            }),
            text: new ol.style.Text({
                font: '12px Calibri,sans-serif'
            })
        });

        this.state = {
            get map() { return this._map; }
        };
    }


    load(o) {
        var countriesLayer = new ol.layer.Vector({
            source: new ol.source.Vector({
                url: o.countriesUrl,
                format: new ol.format.GeoJSON()
            }),
            style: this.defaultStyle
        });
        const destinationSource = new ol.source.Vector();
        const destinationLayer = new ol.layer.Vector({
            source: destinationSource,
            style: this.activeStyle
        });

        const select = new ol.interaction.Select();

        this._map = new ol.Map({
            interactions: ol.interaction.defaults().extend([select]),
            layers: [
                countriesLayer,
                destinationLayer
            ],
            target: o.mapId,
            view: new ol.View({
                center: o.center,
                zoom: 4
            })
        });

        let departure, arrival;
        this._map.on("singleclick", () => {

        });
        select.on('select', e => {
            const selected = e.selected[0];
            if (!selected) {
                return;
            }

            if (departure == null) {
                departure = selected;
            } else {
                if (arrival == null) {
                    if (selected !== departure) {
                        arrival = selected;
                    }
                } else {
                    departure = selected;
                    arrival = null;
                }
            }
            select.getFeatures().clear(true);
            destinationSource.clear(true);
            if (departure) {
                destinationSource.addFeature(departure);
            }
            if (arrival && arrival) {
                destinationSource.addFeature(arrival);
            }

            //console.debug("SELECTED", { evt: e, arrival, departure });
        });
    }

}

EventHandler.injectInto(MapManager.prototype);

export default MapManager;