<template>
  <div>
    <div class="row">
      <div class="col-lg-4">
        <div class="box fixed-height">
          <About />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="box fixed-height">
          <Express />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="box fixed-height">
          <Contact />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="box dark">
          <MessageBoard />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8">
        <div class="box fixed-height">
          <OurMission />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="box fixed-height">
          <Certificate />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import About from "@/localized/nl/components/About";
import Express from "@/localized/nl/components/Express";
import Contact from "@/localized/nl/components/Contact";
import MessageBoard from "@/localized/nl/components/MessageBoard.vue";
import OurMission from "@/localized/nl/components/OurMission.vue";
import Certificate from "@/localized/nl/components/Certificate.vue";

export default {
  components: {
    About,
    Express,
    Contact,
    MessageBoard,
    OurMission,
    Certificate
  }
};
</script>
