<template>
  <div class="career">
    <BackgroundImage :src="pageImage" />
    <PageWrapper>
      <h1>{{ pageTitle }}</h1>
      <component :is="pageView" v-if="pageView" />
    </PageWrapper>
  </div>
</template>

<script>
import { createPageMixins } from "@/regira_modules/vue/categories";

export default {
  mixins: [createPageMixins()]
};
</script>