<template>
  <div>
    <div class="title">
      <h2>ISO 9001:2015</h2>
    </div>
    <div class="content">
      <p>
        <strong>Kwaliteit, klanttevredenheid, professionaliteit, dienstverlening en transparantie, ...</strong>
        het zijn slechts enkele kernwoorden die wij hoog in ons vaandel dragen en die voor ons een dagelijks streefdoel zijn.
        <br>
        <br>Onze ISO certificatie is dan ook het symbool van ons voortdurend streven naar optimalisatie van ons dienstenpakket.
      </p>
      <p align="center">
        <br>
        <br>
        <a
          :href="getDocumentUrl(certificateCode)"
          @click.prevent="openDocument(certificateCode)"
        >Download certificaat</a>
      </p>
    </div>
  </div>
</template>

<script>
import { mapDocuments } from "@/regira_modules/vue/documents";

export default {
  mixins: [mapDocuments()],
  data() {
    return {
      certificateCode: "IsoCertificate"
    };
  }
};
</script>
