<template>
  <div class="row">
    <div class="col-lg-4">
      <div class="box">
        <About/>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="box">
        <Destinations/>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="box">
        <Contact/>
      </div>
    </div>
  </div>
</template>

<script>
import About from "./About";
import Destinations from "./Destinations";
import Contact from "./Contact";

export default {
  components: {
    About,
    Destinations,
    Contact
  }
};
</script>


<style scoped>
.box {
  height: 20rem;
}
a.more {
  display: inline-block;
  padding: 0.5rem;
  color: #fff;
  background-color: #0372ca;
  font-size: 1rem;
}
</style>
