import EntityManager from "@/regira_modules/entities/entity-manager";
import TreeList from "@/regira_modules/treelist.old";

class CategoryManager extends EntityManager {
  setItems(items) {
    this.state.items = new TreeList(items, { fkSelector: (v, p) => v.parentName === p.name });
  }
}

export default CategoryManager;
