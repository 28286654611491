<template>
  <div>
    <div class="row">
      <div class="col-lg-4">
        <div class="box fixed-height">
          <About/>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="box fixed-height">
          <Express/>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="box fixed-height">
          <Contact/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="box dark">
          <MessageBoard/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8">
        <div class="box fixed-height">
          <OurMission/>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="box fixed-height">
          <Certificate/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import About from "@/localized/en/components/About";
import Express from "@/localized/en/components/Express";
import Contact from "@/localized/en/components/Contact";
import MessageBoard from "@/localized/en/components/MessageBoard.vue";
import OurMission from "@/localized/en/components/OurMission.vue";
import Certificate from "@/localized/en/components/Certificate.vue";
export default {
  components: {
    About,
    Express,
    Contact,
    MessageBoard,
    OurMission,
    Certificate
  }
};
</script>
