<template>
  <div class="about">
    <BackgroundImage :src="pageImage"/>
    <PageWrapper>
      <h1>{{ pageTitle }}</h1>
      <div class="row">
        <div class="col-lg-8">
          <component :is="pageView" v-if="pageView"/>
        </div>
        <div class="col-lg-4">
          <SubMenu :parentName="'about'"/>
        </div>
      </div>
    </PageWrapper>
  </div>
</template>

<script>
import SubMenu from "@/components/SubMenu";
import { createPageMixins } from "@/regira_modules/vue/categories";

export default {
  mixins: [createPageMixins()],
  components: { SubMenu }
};
</script>
