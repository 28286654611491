export default {
    create: mapManager => ({
        namespaced: true,
        state: mapManager.state,
        getters: {
            mapId: state => state.mapId,
            layers: state => state.layers,
            features: state => layerName => state.features[layerName]
        },
        mutations: {
        },
        actions: {
            load: async ({ }, payload) => {
                return await mapManager.load(payload);
            }
        }
    })
};