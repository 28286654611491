<template>
  <div class="page-view">
    <div class="row">
      <div class="col-12">
        <div class="box">
          <div class="title">
            <h1>{{ title }}</h1>
          </div>
          <div class="content">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title"]
};
</script>

