<template>
  <div class="who-is-who">
    <BackgroundImage :src="pageImage"/>
    <PageWrapper>
      <div class="row">
        <div class="col-lg-8">
          <h1>{{ employee.name }}</h1>
          <Employee :item="employee"/>
        </div>
        <div class="col-lg-4">
          <SubMenu :parentName="'about'"/>
        </div>
      </div>
    </PageWrapper>
  </div>
</template>

<script>
import employeeMixins from '@/mixins/EmployeeMixins';
import SubMenu from '@/components/SubMenu';
import Employee from '@/employees/Details';
import { last } from '@/regira_modules/utilities/array-utility';

export default {
  mixins: [employeeMixins],
  components: { SubMenu, Employee },
  data() {
    return {
      ignoreView: true
    };
  },
  computed: {
    employeeSlugName() {
      return last(this.$route.path.split("/").filter(s => s));
    },
    employee() {
      return (
        this.getEmployeeFromSlug(this.employeeSlugName) ||
        (this.employeeSlugName &&
          this.employees.length &&
          this.$router.push(this.getRoute("notFound")))
      );
    }
  }
};
</script>
