<template>
  <div :id="mapId"></div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import appConfig from "@/app-config";

const baseUrl = appConfig.baseUrl;

export default {
  data() {
    return {
      mapId: "map"
    };
  },
  computed: {
    ...mapGetters("gis", ["map"])
  },
  methods: {
    ...mapActions("gis", ["load"])
  },
  async mounted() {
    const countriesUrl = `${baseUrl}/data/countries-europe.geojson`;
    const center = [2500000, 6500000];
    //console.debug("MAP", { el: this, center });
    this.$store.dispatch("gis/load", {
      mapId: this.mapId,
      center,
      countriesUrl
    });
  }
};
</script>
