import { mapGetters } from 'vuex';
import { toCamelCase, formatBelgianPhone } from '@/regira_modules/utilities/string-utility';

const employeeMixins = {
    // requires languageMixins
    computed: {
        ...mapGetters('employees', { employees: 'employees' }),
        getEmployee() {
            return id => this.employees.find(x => x.id === id);
        },
        getEmployeeRouteName() {
            return employee => employee ? toCamelCase(employee.name) : null;
        },
        getEmployeeFromSlug() {
            return slug => this.employees.find(x => x.slugName === slug);
        },
        employeeTitles() {
            return employee => ((employee ? employee.titles : null) || []).map(t => t[this.lang]);
        },
        employeesFromDestination() {
            return (destination) => this.employees.filter(x => Array.isArray(x.destinations) && x.destinations.includes(destination));
        },
        formatPhone() {
            return formatBelgianPhone;
        },
        formatEmail() {
            return (email) => email;
        }
    }
};
export default employeeMixins;