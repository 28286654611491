<template>
  <div class="page">
    <BackgroundImage :src="pageImage" />
    <PageWrapper>
      <h1>{{ pageTitle }}</h1>
      <component v-if="pageView" :is="pageView" />
    </PageWrapper>
  </div>
</template>

<script>
import { createPageMixins } from "@/regira_modules/vue/categories";

export default {
  mixins: [createPageMixins()]
};
</script>