import { toCamelCase } from '@/regira_modules/utilities/string-utility';

const helper = {
    createPages(employees, options) {
        const parentRouteName = options.parentRouteName;
        const viewName = options.viewName;
        const languages = options.languages;

        const pages = employees.map(x => {
            const page = {
                "name": toCamelCase(x.name),
                "parentName": parentRouteName,
                "path": {},
                "title": {},
                "view": viewName
            };
            languages.forEach(lang => {
                page.path[lang] = '/' + x.slugName;
                page.title[lang] = x.name;
            });
            return page;
        });

        return pages;
    }
};

export default helper;