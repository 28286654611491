import employees from './employees';

import gisFactory from '@/gis/map-module';
import MapManager from '@/gis/map-manager';

const mm = new MapManager();

export default {
    employees,
    gis: gisFactory.create(mm)
};