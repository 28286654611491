import { slugify } from '@/regira_modules/utilities/string-utility';
import EntityManager from '@/regira_modules/entities/entity-manager';

class EmployeeManager extends EntityManager {
    setItems(items = []) {
        this.state.items = items
            .map(x => {
                x.slugName = slugify(x.name);
                return x;
            });
    }
}

export default EmployeeManager;