import { trimRight } from '@/regira_modules/utilities/string-utility';

const baseUrl = trimRight(process.env.BASE_URL, '/');
const entityApi = `${baseUrl}/data`;
//const entityApi = 'https://welpa-trans.firebaseio.com';
const version = require('../package.json').version;

const config = {
    development: {
        mode: 'DEV',
        entityApi,
        baseUrl,
        version
    },
    production: {
        mode: 'PROD',
        entityApi,
        baseUrl,
        version
    },
    ['production.local']: {
        mode: 'PROD.LOCAL',
        entityApi,
        baseUrl,
        version
    }
};

export default config[process.env.NODE_ENV];