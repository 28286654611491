<template>
  <div class="company-information">
    <BackgroundImage :src="pageImage" />
    <PageWrapper>
      <h1>{{ pageTitle }}</h1>
      <div class="row">
        <div class="col-lg-8">
          <dl class="row">
            <template v-for="(value, key) in company">
              <dt
                class="col-sm-6 col-md-4"
                :key="key + '_1'"
                v-if="key != 'banks'"
              >{{ translate(key) }}</dt>
              <dd
                class="col-sm-6 col-md-8 pre"
                :key="key + '_2'"
                v-if="key != 'banks'"
              >{{ getLocalizedValue(value) }}</dd>
            </template>
          </dl>
          <p>
            <strong>{{ translate('banks') }}</strong>
          </p>
          <dl class="row">
            <template v-for="bank in company.banks">
              <dt class="col-sm-6 col-md-4">{{ bank.name }}</dt>
              <dd class="col-sm-6 col-md-8 pre"></dd>
              <template v-for="(value, key) in bank">
                <dt
                  class="col-sm-6 col-md-4 light"
                  :key="bank.name + '_' + key + '_1'"
                  v-if="key != 'name'"
                >{{ translate(key) }}</dt>
                <dd
                  class="col-sm-6 col-md-8 pre"
                  :key="bank.name + '_' + key + '_2'"
                  v-if="key != 'name'"
                >{{ value }}</dd>
              </template>
            </template>
          </dl>
        </div>
        <div class="col-lg-4">
          <SubMenu :parentName="'about'" />
        </div>
      </div>
    </PageWrapper>
  </div>
</template>

<script>
import SubMenu from "@/components/SubMenu";
import { createPageMixins } from "@/regira_modules/vue/categories";

export default {
  mixins: [createPageMixins()],
  components: { SubMenu }
};
</script>
