<template>
  <footer>
    <div class="container">
      <ul class="list-inline">
        <li class="list-inline-item" v-for="category in footerCategories" :key="category.name">
          <router-link :to="getRoute(category.name)" class="nav-link">{{ navTitle(category) }}</router-link>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
import { getFooterCategories } from "@/regira_modules/vue/categories/category-utility";

export default {
  computed: {
    footerCategories() {
      return getFooterCategories(this.categories);
    }
  }
}
</script>