import Vue from 'vue';
import App from '@/App.vue';
import store from '@/store/store';
import router from '@/routing/router';

import '@/assets/css/main.scss';

// polyfills
import '@/regira_modules/polyfills/array-flat';
import '@/regira_modules/polyfills/object-from-entries';
import '@/regira_modules/polyfills/url';
import '@/regira_modules/polyfills/url-search-params';

// HTTPS
import { forceHttps, isLocalHost } from '@/regira_modules/utilities/http-utility';
if (!isLocalHost()) {
  forceHttps(document.URL);
}

// treelist
import { TreeList } from '@/regira_modules/treelist.old';
TreeList.injectArrayPrototype();

// PageWrapper
import PageWrapper from '@/components/PageWrapper';
Vue.component('PageWrapper', PageWrapper);

import BackgroundImage from '@/components/BackgroundImage';
Vue.mixin({
  components: {
    BackgroundImage
  },
  computed: {
    pageImage() {
      return this.$route.meta && this.$route.meta.pageImage ? this.$route.meta.pageImage : DefaultImage;
    }
  }
});

import appConfig from '@/app-config';
import DefaultImage from '@/assets/images/welpatrans-overlay.jpg';
import employeePageConverter from '@/routing/employee-page-converter';
import pageModules from '@/views';
import localizedModules from "@/localized";
import ioc from '@/infrastructure/ioc';
import { languageUtility } from '@/regira_modules/vue/multilanguage';

// plugins
import multilanguagePlugin from '@/regira_modules/vue/multilanguage';
import routePlugin from '@/regira_modules/vue/routing';
import categoryPlugin from '@/regira_modules/vue/categories';
import settingsPlugin from '@/regira_modules/vue/generic-settings';
import documentPlugin from '@/regira_modules/vue/documents';

Vue.config.productionTip = false

const handleLoadFail = (error) => { console.error("Failed to load", { error }); return undefined; };

// translations must be loaded before app is mounted
Promise.all([
  ioc.translationManager.details().catch(handleLoadFail),
  ioc.categoryManager.list().catch(handleLoadFail),
  ioc.sitesettingsManager.details().catch(handleLoadFail),
  ioc.employeeManager.list().catch(handleLoadFail),
  ioc.urlmappingManager.list().catch(handleLoadFail)
])
  .then(([translations = {}, categories = [], siteSettings = {}, employees = [], oldRoutes = []]) => {
    //console.debug("LOADED", { translations, categories, siteSettings, employees, oldRoutes });

    // multilanguage
    Vue.use(multilanguagePlugin, {
      service: ioc.translationManager,
      store,
      router,
      translations
    });

    // routing
    Vue.use(routePlugin, {
      router,
      baseUrl: appConfig.baseUrl
    });

    // employees
    employeePageConverter.createPages(employees, {
      languages: Vue.prototype.$lang.languages,
      parentRouteName: 'whoIsWho',
      viewName: 'Employee'
    }).forEach(employee => {
      categories.add(employee);
    });

    // categories
    Vue.use(categoryPlugin, {
      service: ioc.categoryManager,
      categories,
      titleFormat: `{title} | ${languageUtility.getLocalizedValue(siteSettings.companyName, 'en')}`,
      store,
      router,
      pageModules,
      localizedModules,
      oldRoutes
    });

    Vue.use(documentPlugin, {
      service: ioc.documentManager,
      store,
      baseFolder: '/files'
    });

    Vue.use(settingsPlugin, {
      service: ioc.sitesettingsManager,
      store,
      moduleName: 'company',
      propertyName: 'company'
    });

    new Vue({
      router,
      store,
      render: h => h(App),
      async created() {
        // load data
        await Promise.all([
          // store.dispatch('translations/load'),
          // store.dispatch('company/load'),
          // store.dispatch('categories/load'),
          store.dispatch('documents/load'),
          store.dispatch('employees/load')
        ]);

        console.debug("CREATED", { router: this.$router, store: this.$store, version: appConfig.version });
      }
    }).$mount('#app');
  })
  .catch((error) => console.error(error));
