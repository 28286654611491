import { render, staticRenderFns } from "./MessageBoard.vue?vue&type=template&id=84c3b39e&"
import script from "./MessageBoard.vue?vue&type=script&lang=js&"
export * from "./MessageBoard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports