<template>
  <div>
    <BackgroundImage :src="pageImage" />
    <PageWrapper>
      <h1>{{ pageTitle }}</h1>
      <component :is="pageView" v-if="pageView" v-bind="{ destinations }" />
    </PageWrapper>
  </div>
</template>

<script>
import { createPageMixins } from "@/regira_modules/vue/categories";

export default {
  mixins: [createPageMixins()],
  data() {
    return {
      destinations: ["EU_WEST", "EU_EAST"]
    };
  }
};
</script>